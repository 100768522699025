import loadable from "@loadable/component";
import { IRoute } from "app/routes";
import { loadableConfig } from "app/routes/loadableConfig";

import SummarizeIcon from "@mui/icons-material/Summarize";

export const LogsRoutes: IRoute = {
	id: "logs",
	guid: undefined,

	path: "/Logs",
	Component: loadable(() => import("./"), loadableConfig),

	icon: <SummarizeIcon />,
	sidebarPath: "/Logs",

	modules: [
		{
			id: "logsSiteAllocator",
			guid: undefined,

			path: "/Logs/SiteAllocator",
			Component: loadable(() => import("./modules/SiteAllocator"), loadableConfig),
		},
		{
			id: "logsDLQManagement",
			guid: undefined,

			path: "/Logs/DLQManagement",
			Component: loadable(() => import("./modules/DLQManagement"), loadableConfig),
		},
		{
			id: "logsReprocessor",
			guid: undefined,

			path: "/Logs/Reprocessor",
			Component: loadable(() => import("./modules/Reprocessor"), loadableConfig),
		},
	],
};
