import loadable from "@loadable/component";
import { IRoute } from "app/routes";
import { loadableConfig } from "app/routes/loadableConfig";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

export const AccountsManagerRoutes: IRoute = {
	id: "accountManager",
	guid: undefined,

	path: "/AccountManager",
	Component: loadable(() => import("./"), loadableConfig),

	icon: <ManageAccountsIcon />,
	sidebarPath: "/AccountManager",
};
