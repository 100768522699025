import React, { useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import "simplebar-react/dist/simplebar.min.css";

import {
	DashboardLayout,
	useNavigationState,
	DashboardMain,
	DashboardSidebar,
	DashboardHeader,
	INavigationItem,
} from "@cbe/ui";
import { IUser, useAuthentication, useAuthorisation } from "@cbe/auth";

import { PowerMenu } from "./PowerMenu";
import { IRoute, routes } from "app/routes";

function renderNavItem(
	{ id, guid, roles, modules = [], icon, sidebarPath, shouldShowInSidebar }: IRoute,
	getRouteLabel: (item: string) => string,
	checkUserHasPrivilege: (moduleGuid: string) => boolean,
	checkUserHasAnyRole: (roles: string[]) => boolean,
	activeUser: IUser
) {
	if (!sidebarPath && modules.length === 0) {
		return null;
	}
	// Should the route be displayed in the sidebar?
	const shouldShow = shouldShowInSidebar ? shouldShowInSidebar(activeUser) : true;

	// If no guid is passed in then we assume the route doesn't depend on modules.
	const validModule = guid === undefined ? true : checkUserHasPrivilege(guid);

	// If no roles are passed in then we assume the route doesn't depend on roles.
	const validRole = roles ? checkUserHasAnyRole(roles) : true;

	if (!shouldShow || !validRole || !validModule) {
		return null;
	}

	const navItem: INavigationItem = {
		id,
		path: sidebarPath,
		label: getRouteLabel(id),
		icon: icon || <React.Fragment></React.Fragment>,
	};

	// Recursively map modules onto this function to check if there is any further routes.
	const childRoutes = modules
		.map((child) =>
			renderNavItem(child, getRouteLabel, checkUserHasPrivilege, checkUserHasAnyRole, activeUser)
		)
		.filter(Boolean) as INavigationItem[];

	if (childRoutes.length > 0) {
		navItem.children = childRoutes;
	}

	return navItem;
}

export default memo(function _DashboardLayout({
	children,
}: {
	children: React.ReactElement;
}): JSX.Element {
	const { t } = useTranslation(["common"]);
	const { activeAccount } = useAuthentication();
	const { activeUser, checkUserHasAnyRole, checkUserHasPrivilege } = useAuthorisation();

	const navItems = useMemo<INavigationItem[]>(() => {
		function getRouteLabel(id: string) {
			return t(`common:pages.${id}.navigation` as any);
		}

		const _items = [];
		if (activeUser) {
			for (const route of routes) {
				const _item = renderNavItem(
					route,
					getRouteLabel,
					checkUserHasPrivilege,
					checkUserHasAnyRole,
					activeUser
				);

				if (_item) _items.push(_item);
			}
		}
		return _items;
	}, [activeUser, t, checkUserHasPrivilege, checkUserHasAnyRole]);

	const navigation = useNavigationState(navItems);

	return (
		<DashboardLayout navigation={navigation}>
			<DashboardHeader navigation={navigation} MenuComponent={<PowerMenu />} />

			<DashboardSidebar
				navigation={navigation}
				usersName={activeAccount?.name || ""}
				organisationName="CBE"
			/>

			<DashboardMain navigation={navigation}>{children}</DashboardMain>
		</DashboardLayout>
	);
});
