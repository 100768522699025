import loadable from "@loadable/component";
import { IRoute } from "app/routes";
import { loadableConfig } from "app/routes/loadableConfig";

import StorefrontIcon from "@mui/icons-material/Storefront";

export const SiteManagementRoutes: IRoute = {
	id: "siteManagement",
	guid: undefined,

	path: "/SiteManagement/*",
	Component: loadable(() => import("./"), loadableConfig),
	children: [
		{
			index: true,
			Component: loadable(() => import("./modules/Index"), loadableConfig),
		},
		{
			path: ":id",
			Component: loadable(() => import("./components/SiteOverview"), loadableConfig),
		},
		{
			path: ":id/Regenerator",
			Component: loadable(() => import("./modules/Regenerator"), loadableConfig),
		},
		{
			path: ":id/Reprocessor/*",
			Component: loadable(() => import("./modules/Reprocessor"), loadableConfig),
			children: [
				{
					index: true,
					Component: loadable(() => import("./modules/Reprocessor/modules/Index"), loadableConfig),
				},
				{
					path: "Transaction",
					Component: loadable(
						() => import("./modules/Reprocessor/modules/Transaction"),
						loadableConfig
					),
				},
			],
		},
		{
			path: "AddOrganisation",
			Component: loadable(() => import("./pages/AddOrganisation"), loadableConfig),
		},
		{
			path: "EditOrganisation/:id",
			Component: loadable(() => import("./pages/EditOrganisation"), loadableConfig),
		},
		{
			path: "AddSite",
			Component: loadable(() => import("./pages/AddSite"), loadableConfig),
		},
		{
			path: "EditSite/:id",
			Component: loadable(() => import("./pages/EditSite"), loadableConfig),
		},
		{
			path: ":id/SetupCBEPay",
			Component: loadable(() => import("./modules/SetupCBEPay"), loadableConfig),
		},
	],

	icon: <StorefrontIcon />,
	sidebarPath: "/SiteManagement",
};
