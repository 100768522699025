import loadable from "@loadable/component";
import { createBrowserRouter } from "react-router-dom";
import { AuthenticationWrapper } from "@cbe/auth";

import { LoginLayout } from "app/components/LoginLayout";
import { ErrorComponent } from "app/components/ErrorComponent";
import { loadableConfigAuth } from "./loadableConfig";
import { renderRoutes, IRoute } from "./renderRoutes";

import { SiteAllocatorRoutes } from "app/pages/SiteAllocator/routes";
import { AccountsManagerRoutes } from "app/pages/AccountManager/routes";
import { LogsRoutes } from "app/pages/Logs/routes";
import { MonitorRoutes } from "app/pages/Monitoring/routes";
import { SiteManagementRoutes } from "app/pages/SiteManagement/routes";
import { DLQManagementRoutes } from "app/pages/DLQ_Management/routes";
import { CloudFeatureRoutes } from "app/pages/Features/routes";

const Login = loadable(() => import("app/pages/Login"), loadableConfigAuth);
const Redirect = loadable(() => import("app/pages/Redirect"), loadableConfigAuth);
const PageNotFound = loadable(() => import("app/pages/Errors/PageNotFound"), loadableConfigAuth);

/**
 * `routes` is the array of all routes inside of the application.
 *
 * The order of the routes array is the order the routes will be displayed in the sidebar navigation.
 */
export const routes: IRoute[] = [
	SiteAllocatorRoutes,
	AccountsManagerRoutes,
	LogsRoutes,
	MonitorRoutes,
	SiteManagementRoutes,
	DLQManagementRoutes,
	CloudFeatureRoutes,
];

export function createAppRoutes(): ReturnType<typeof createBrowserRouter> {
	return createBrowserRouter([
		{
			index: true,
			element: (
				<LoginLayout>
					<Login />
				</LoginLayout>
			),
			ErrorBoundary: ErrorComponent,
		},
		{
			path: "/Redirect/*",
			element: (
				<LoginLayout>
					<Redirect />
				</LoginLayout>
			),
			ErrorBoundary: ErrorComponent,
		},
		...renderRoutes(routes),
		{
			path: "*",
			element: (
				<AuthenticationWrapper>
					<PageNotFound />
				</AuthenticationWrapper>
			),
			ErrorBoundary: ErrorComponent,
		},
	]);
}
