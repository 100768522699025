import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	IconButton,
	ListItem,
	List,
	ListItemIcon,
	ListItemText,
	Popover,
	Paper,
} from "@mui/material";
import Brightness5Icon from "@mui/icons-material/Brightness5";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useAuthentication } from "@cbe/auth";
import { useColourMode, useTheme } from "@cbe/ui";

export const PowerMenu = (): JSX.Element => {
	const { t } = useTranslation(["common"]);
	const { activeAccount, logout } = useAuthentication();

	const { setActiveTheme } = useTheme();
	const { colourMode, toggleColourMode } = useColourMode(activeAccount?.id || "");

	useEffect(() => {
		setActiveTheme(colourMode === "light" ? "dark" : "night");
	}, [colourMode, setActiveTheme]);

	const iconRef = useRef(null);
	const [openMenu, setOpenMenu] = useState(null);
	function handleOpenMenu() {
		setOpenMenu(iconRef.current);
	}
	function handleCloseMenu() {
		setOpenMenu(null);
	}

	return (
		<React.Fragment>
			<IconButton
				ref={iconRef}
				id="power-menu-button"
				onClick={handleOpenMenu}
				aria-label={t("common:header.power-menu")}
				aria-describedby={openMenu === iconRef.current ? "power-menu" : undefined}
				color="inherit"
				size="large"
			>
				<PowerSettingsNewIcon />
			</IconButton>

			{iconRef.current ? (
				<Popover
					anchorEl={iconRef.current}
					id="power-menu"
					open={openMenu === iconRef.current}
					onClose={handleCloseMenu}
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					transformOrigin={{ vertical: "top", horizontal: "right" }}
				>
					<Paper>
						<List dense>
							<ListItem onClick={toggleColourMode} button>
								<ListItemIcon>
									{colourMode === "dark" ? <Brightness7Icon /> : <Brightness5Icon />}
								</ListItemIcon>

								<ListItemText
									primary={
										colourMode === "dark"
											? t("common:action.toggle-light-mode")
											: t("common:action.toggle-dark-mode")
									}
								/>
							</ListItem>

							<ListItem onClick={logout} button>
								<ListItemIcon>
									<ExitToAppIcon />
								</ListItemIcon>

								<ListItemText primary={t("common:action.sign-out")} />
							</ListItem>
						</List>
					</Paper>
				</Popover>
			) : null}
		</React.Fragment>
	);
};
