import React from "react";
import ReactDOM from "react-dom";
import loadable from "@loadable/component";

import { authClient } from "app/libs/cbe-auth";
const App = loadable(() => import("./App"));

const rootElement = document.getElementById("root");

// We need to check if msal is tring to redirect using popup, if so we want to stop the app from rendering.
if (authClient.authentication.hasPopupHash) {
	if (rootElement?.textContent !== undefined) {
		rootElement.textContent = "Redirecting to application...";
	}

	// Wait upto 2 seconds and close window if login redirect fails.
	setTimeout(() => {
		window.close();
	}, 2000);
} else {
	ReactDOM.render(<App authClient={authClient} />, rootElement);
}
