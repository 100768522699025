import loadable from "@loadable/component";
import { IRoute } from "app/routes";
import { loadableConfig } from "app/routes/loadableConfig";

import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import { UserRole } from "@cbe/auth";

export const DLQManagementRoutes: IRoute = {
	id: "dlqManagement",
	guid: undefined,
	roles: [UserRole.CBESupportAdvanced],

	path: "/DLQManagement/*",
	Component: loadable(() => import("./"), loadableConfig),
	children: [
		{
			index: true,
			Component: loadable(() => import("./modules/Index"), loadableConfig),
		},
		{
			path: ":id",
			Component: loadable(() => import("./modules/ViewMessage"), loadableConfig),
		},
	],

	icon: <MarkunreadMailboxIcon />,
	sidebarPath: "/DLQManagement",
};
