// Node runtime environments
//

export const __DEV__ = process.env.NODE_ENV === "development";
export const __TEST__ = process.env.NODE_ENV === "test";
export const __PROD__ = process.env.NODE_ENV === "production";

export const PUBLIC_URL = process.env.PUBLIC_URL;
export const DEPLOYMENT_URL = process.env.REACT_APP_SUPPORT_DEPLOYMENT_URL;
export const DEPLOYMENT_URL_CBE_CLOUD = process.env.REACT_APP_DEPLOYMENT_URL;

export const APIM_URI = process.env.REACT_APP_APIM_URI as string;

export const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
export const AUTH_AUTHORITY_URI = process.env.REACT_APP_AUTH_AUTHORITY_URI;
export const AUTH_TENANT_ID = process.env.REACT_APP_AUTH_TENANT_ID;
export const AUTH_TOKENS_URI = process.env.REACT_APP_AUTH_TOKENS_URI;

export const APP_INSIGHTS_CONNECTION_STRING = process.env.REACT_APP_CONNECTION_STRING;
export const POWER_BI_WORKSPACE_ID = process.env.REACT_APP_POWER_BI_WORKSPACE_ID || "";

// App Versioning
// [ASCII Art Generator](http://patorjk.com/software/taag/#p=display&f=Slant&t=CBE%20Cloud)
//

export const APP_VERSION = process.env.REACT_APP_VERSION;
export const APP_INIT_MESSAGE = `   __________  ______   ________                __
  / ____/ __ )/ ____/  / ____/ /___  __  ______/ /
 / /   / __  / __/    / /   / / __ \\/ / / / __  /
/ /___/ /_/ / /___   / /___/ / /_/ / /_/ / /_/ /
\\____/_____/_____/   \\____/_/\\____/\\__,_/\\__,_/

App Version: ${APP_VERSION}`;
