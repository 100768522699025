import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import LockIcon from "@mui/icons-material/Lock";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	div.icon {
		display: flex;
		justify-content: center;
		padding-bottom: ${({ theme }) => theme.spacing(4)};
		> svg {
			width: 50px;
			height: 50px;
		}
	}
	div.message {
		display: flex;
		flex-direction: column;
		align-items: center;

		> h3 {
			padding-bottom: ${({ theme }) => theme.spacing(2)};
		}
	}
`;

export const UserUnauthorised = (): JSX.Element => {
	const { t } = useTranslation(["common"]);

	return (
		<Container id="user-unauthorised">
			<div className="icon">
				<LockIcon />
			</div>

			<div className="message">
				<Typography variant="h4" component="h3">
					{t("common:information.module-access")}
				</Typography>

				<Typography variant="body1">{t("common:information.contact-administrator")}</Typography>
			</div>
		</Container>
	);
};
