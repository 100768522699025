import loadable from "@loadable/component";
import { IRoute } from "app/routes";
import { loadableConfig } from "app/routes/loadableConfig";

import DomainIcon from "@mui/icons-material/Domain";

export const SiteAllocatorRoutes: IRoute = {
	id: "siteAllocator",
	guid: undefined,

	path: "/SiteAllocator",
	Component: loadable(() => import("./"), loadableConfig),

	icon: <DomainIcon />,
	sidebarPath: "/SiteAllocator",
};
