import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ErrorLayout } from "@cbe/ui";

export const ErrorComponent = (): JSX.Element => {
	const { t, ready } = useTranslation(["common"], { useSuspense: false });

	function reloadPage() {
		window.location.reload();
	}

	return (
		<ErrorLayout title={ready ? t("common:error.generic") : "An Error Occurred."}>
			<InfoOutlinedIcon />

			<p className="error-message">{ready ? t("common:error.generic") : "An Error Occurred."}</p>

			<span>
				<Button color="inherit" variant="outlined" onClick={reloadPage}>
					{ready ? t("common:action.reload-page") : "Reload Page"}
				</Button>
			</span>
		</ErrorLayout>
	);
};
