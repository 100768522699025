import React, { memo, useMemo } from "react";
import styled from "@emotion/styled";
import { CssBaseline } from "@mui/material";
import { grey } from "@mui/material/colors";
import { CBECloudLogo } from "@cbe/ui";
import { __TEST__ } from "app/constants";

const LOCATION = "/static/images/login";
const BACKGROUND_IMAGES = [
	"Man_Point_Of_Sale.jpg",
	"Women_Shopping.jpg",
	"alexandru-tugui--inuQpBGbgI-unsplash.jpg",
];

/**
 * Function to grab a random image and save it to session
 * storage so we can reuse the same image throughout the
 * browsers session.
 */
function getRandomImage() {
	// No easy way to mock a function that is not imported
	// from an external file, so this will have to do.
	if (__TEST__) {
		return BACKGROUND_IMAGES[0];
	}

	const sessionImage = window.sessionStorage.getItem("login-background-image");
	if (sessionImage) {
		return sessionImage;
	}

	const randomImage = BACKGROUND_IMAGES[Math.floor(Math.random() * BACKGROUND_IMAGES.length)];

	window.sessionStorage.setItem("login-background-image", randomImage);
	return randomImage;
}

const PageWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
`;
const Root = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	place-items: center;
	z-index: 1;
`;
const Card = styled.div`
	min-width: 300px;
	width: 90%;
	max-width: 1000px;
	min-height: 500px;
	height: 75%;
	max-height: 900px;
	display: flex;

	box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
		rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
	border-radius: ${({ theme }) => theme.spacing(6)};
	overflow: hidden;

	div.main-content {
		width: 65%;
		height: 100%;
		min-height: 300px;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		background-color: ${grey[900]};
		color: ${grey[50]};
	}
	div.logo-container {
		width: 45%;
		height: 100%;
		min-height: 100px;

		display: flex;
		justify-content: center;
		align-items: center;

		background-color: ${grey[50]};
	}

	@media only screen and (max-width: 750px) {
		flex-direction: column-reverse;

		div.main-content {
			width: 100%;
			min-height: 100px;
		}
		div.logo-container {
			width: 100%;
			min-height: 80px;
			height: 120px;
			max-height: 200px;

			img.product-logo {
				width: 218px;
				height: 87px;
			}
		}
	}
`;
const Background = styled.div<{ image: string }>`
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 0;

	${({ image }) => image && `background-image: url(${image});`}
	background-size: cover;
	background-position: center;

	div.background-image-gradient {
		width: 100%;
		height: 100%;
		background: linear-gradient(0deg, rgba(20, 20, 20, 0.8) 0%, rgba(100, 100, 100, 0.7) 100%);
	}
`;

type LoginWrapperProps = {
	children: React.ReactNode;
};

export default memo(function _LoginWrapper({ children }: LoginWrapperProps): JSX.Element {
	const image = useMemo(() => getRandomImage(), []);

	return (
		<React.Fragment>
			<CssBaseline />

			<PageWrapper className="page-wrapper">
				<Root className="auth-layout">
					<Card>
						<div className="main-content">{children}</div>

						<div className="logo-container">
							<CBECloudLogo size="md" />
						</div>
					</Card>
				</Root>

				<Background className="background-image" image={`${LOCATION}/${image}`}>
					<div className="background-image-gradient">&nbsp;</div>
				</Background>
			</PageWrapper>
		</React.Fragment>
	);
});
