import { AuthClient } from "@cbe/auth";
import {
	APIM_URI,
	APP_INSIGHTS_CONNECTION_STRING,
	APP_VERSION,
	AUTH_AUTHORITY_URI,
	AUTH_CLIENT_ID,
	AUTH_TENANT_ID,
	AUTH_TOKENS_URI,
	__PROD__,
} from "app/constants";
import { routes } from "app/routes";

export const authClient = new AuthClient({
	analytics:
		__PROD__ && APP_INSIGHTS_CONNECTION_STRING
			? { connectionString: `${APP_INSIGHTS_CONNECTION_STRING}` }
			: undefined,
	authentication: {
		authority: `${AUTH_AUTHORITY_URI}/${AUTH_TENANT_ID}`,
		clientId: `${AUTH_CLIENT_ID}`,
		tokenUri: `${AUTH_TOKENS_URI}`,
		redirectUri: `${window.location.origin}/Redirect`,
		postLogoutRedirectUri: window.location.origin,
		appName: "App-Support",
		appVersion: APP_VERSION,
	},
	authorisation: {
		baseUrl: APIM_URI,
		routes: routes,
	},
});
