import loadable from "@loadable/component";
import { IRoute } from "app/routes";
import { loadableConfig } from "app/routes/loadableConfig";

import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { UserRole } from "@cbe/auth";

export const CloudFeatureRoutes: IRoute = {
	id: "features",
	guid: undefined,
	roles: [UserRole.CBESupportDev],

	path: "/Features/*",
	Component: loadable(() => import("./"), loadableConfig),

	icon: <ToggleOnIcon />,
	sidebarPath: "/Features",
};
