import loadable from "@loadable/component";
import { Loading } from "@cbe/ui";

export const loadableConfig: Parameters<typeof loadable>[1] = {
	fallback: <Loading />,
};

export const loadableConfigAuth: Parameters<typeof loadable>[1] = {
	fallback: <Loading color="inherit" fullHeight={false} />,
};
