import loadable from "@loadable/component";
import { IRoute } from "app/routes";
import { loadableConfig } from "app/routes/loadableConfig";

import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";

export const MonitorRoutes: IRoute = {
	id: "monitoring",
	guid: undefined,

	path: "/Monitoring",
	Component: loadable(() => import("./"), loadableConfig),

	icon: <MonitorHeartIcon />,
	sidebarPath: "/Monitoring",
};
